<template>
  <v-row
    justify="space-between"
    class="head-search ma-0">
    <v-col
      cols="auto"
      class="d-flex align-center date-picker-col">
      <date-range-picker v-model="filter.dates" />
    </v-col>
    <v-spacer />
    <v-col
      cols="4"
      class="d-flex justify-end align-center date-picker-col">
      <search-box
        v-model="filter.searchInput"
        :disabled="findBtnDisabled"
        @on-search="find()" />
    </v-col>
  </v-row>
</template>

<script>
import SearchBox from '@/components/SearchBox.vue'
import DateRangePicker from './DatesPicker.vue'

export default {
  components: {
    DateRangePicker,
    SearchBox
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    filter: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    findBtnDisabled () {
      return this.filter.warehouse === null
    }
  },
  methods: {
    find () {
      this.$emit('on-find')
    }
  }
}
</script>
